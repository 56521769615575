<template>
  <div>
    <monitor-camera-info :camera-data="selectPlayCamera" v-if="isShowCameraInfo" :position-left="cameraInfoLeft" :position-top="cameraInfoTop" @cameraInfoClose="cameraInfoClose"/>
    <monitor-camera-play ref="monitorCameraPlay" :camera-data="selectPlayCamera" v-if="isShowPlayer" :is-show-camera-info="isShowCameraInfo" :is-show-pt-z="isShowPtZ"
                         :position-left="cameraPlayLeft" :position-top="cameraPlayTop" :button-visible="buttonVisible"
                         @cameraInfoClose="cameraInfoClose" @cameraPtzClose="cameraPtzClose" @cameraPlayClose="cameraPlayClose"/>
    <monitor-camera-ptz :camera-data="selectPlayCamera" v-if="isShowPtZ" :position-left="cameraPtzLeft" :position-top="cameraPtzTop" @cameraPtzClose="cameraPtzClose"/>
  </div>
</template>

<script>
import monitorCameraInfo from "../../components/monitor/monitorCameraVideoPlay/MonitorCameraInfo"
import monitorCameraPlay from "../../components/monitor/monitorCameraVideoPlay/MonitorCameraPlay"
import monitorCameraPtz from "../../components/monitor/monitorCameraVideoPlay/MonitorCameraPtz"
import { getNoExistNavigatorBodyHeignt,getBodyWidth } from '@/core/bodyHeigntUtil'
export default {
  name: "MonitorCameraVideoPlay",
  props: {
    selectPlayCamera:{},
    buttonVisible: { // 控制按钮是否可见,目前仅对9分屏进行显隐控制
      type: Object,
      default: () => {
        return {
          nineScreen: true
        }
      }
    }
  },
  data(){
    return{
      cameraPlayWidth:512,
      cameraSpace:8,
      cameraPlayDefaultHeight:350,
      cameraOtherWidth:288,
      cameraPlayLeft:0,
      cameraPlayTop:0,
      cameraPtzLeft:0,
      cameraPtzTop:0,
      cameraInfoLeft:0,
      cameraInfoTop:0,
      isShowCameraInfo:false,
      isShowPtZ:false,
      isShowPlayer:true
    }
  },
  components:{
    monitorCameraPlay,
    monitorCameraInfo,
    monitorCameraPtz
  },
  methods:{
    setCameraPlay(){
      let bodyWidth = getBodyWidth()
      let bodyHeight = getNoExistNavigatorBodyHeignt()
      this.cameraPlayLeft = (bodyWidth - this.cameraPlayWidth) / 2
      this.cameraPlayTop = (bodyHeight - this.cameraPlayDefaultHeight) /2
    },
    setCameraInfo(){
      this.cameraInfoTop = this.cameraPlayTop
      this.cameraInfoLeft = this.cameraPlayLeft -this.cameraOtherWidth -this.cameraSpace
    },
    setCameraPtz(){
      this.cameraPtzTop = this.cameraPlayTop
      this.cameraPtzLeft = this.cameraPlayLeft + this.cameraPlayWidth + this.cameraSpace
    },
    setCameraPlayLeft_Top(){
      this.setCameraPlay()
      this.setCameraInfo()
      this.setCameraPtz()
    },
    cameraInfoClose(value){
      this.isShowCameraInfo = value
    },
    cameraPtzClose(value){
      this.isShowPtZ = value
    },
    cameraPlayClose(type){
      this.isShowPlayer = false
      this.isShowPtZ = false
      this.isShowCameraInfo = false
      if (type === 0) { // 同时关闭1分屏和4分屏窗口
        this.$emit('cameraPlayClose')
      } else if (type === 1) { // 只关闭1分屏窗口,保留隐藏的4分屏窗口
        this.$emit('cameraVideoCloseButFourScreenClick')
      } else if (type === 2) { // 只关闭1分屏窗口,保留隐藏的9分屏窗口
          this.$emit('cameraVideoCloseButNineScreenClick')
      }
    },
    cameraPlay(cameraNum){
      console.log(cameraNum)
    },
    play (camera) {
      this.$refs.monitorCameraPlay.getCameraPlayerUrl(camera)
    }
  },
  mounted() {
    this.setCameraPlayLeft_Top()
  }
}
</script>

<style scoped>

</style>
