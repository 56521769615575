<!--
摄像机视频播放窗口云台控制
1:向左 2:向右 3:向上 4:向下 5:变倍短
6:变倍长 7:聚焦近 8:聚焦远 9:光圈小
10:光圈大 11:灯光关 12:灯光开
-->
<template>
  <div class="monitorCameraPtz">
    <div class="layerControl" :style="{left:positionLeft+'px',top:positionTop+'px'}">
      <div class="layerTop"></div>
      <div class="layerMiddle"></div>
      <div class="layerBottom"></div>
      <div class="layerContent">
        <div style="width: 288px;padding:0px 16px 0px 16px;">
          <a-row style="height: 18px;line-height: 18px;">
            <a-col :span="23" class="ptz-name-text" >
             云台控制
            </a-col>
            <a-col :span="1">
              <div class="play-close" @click="cameraPtzClose"></div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <table class="ptz-table">
                <tr class="ptz-table-tr">
                  <td class="ptz-table-tr-td_left_top" ></td>
                  <td class="ptz-table-tr-td_middle_top" @click="ptzControl(3)"></td>
                  <td class="ptz-table-tr-td_right_top"></td>
                </tr>
                <tr class="ptz-table-tr">
                  <td class="ptz-table-tr-td_left_middle" @click="ptzControl(1)"></td>
                  <td class="ptz-table-tr-td_middle_middle"></td>
                  <td class="ptz-table-tr-td_middle_right" @click="ptzControl(2)"></td>
                </tr>
                <tr class="ptz-table-tr">
                  <td class="ptz-table-tr-td_left_bottom"></td>
                  <td class="ptz-table-tr-td_middle_bottom" @click="ptzControl(4)"></td>
                  <td class="ptz-table-tr-td_right_bottom"></td>
                </tr>
              </table>
              <table style="margin-top: 67px">
                <tr style="height: 25px;">
                  <td>
                    <div class="light-text" >灯光：</div>
                  </td>
                  <td>
                    <div :class="{'light-image-off': lightParam === 11, 'light-image-on': lightParam === 12}" @click="lightClick"></div>
                  </td>
                </tr>
              </table>
            </a-col>
            <a-col :span="12" style="margin-top: 45px;">
              <div style="width:120px;margin-left: 8px;">
                <a-row>
                  <a-col :span="8">
                    <div class="reduce-left" @click="ptzControl(5)"></div>
                  </a-col>
                  <a-col :span="8">
                    <div class="reduce-middle-zoom"></div>
                  </a-col>
                  <a-col :span="8">
                    <div class="reduce-right" @click="ptzControl(6)"></div>
                  </a-col>
                </a-row>
                <a-row style="margin-top: 12px">
                  <a-col :span="8">
                    <div class="reduce-left" @click="ptzControl(7)"></div>
                  </a-col>
                  <a-col :span="8">
                    <div class="reduce-middle-focus"></div>
                  </a-col>
                  <a-col :span="8">
                    <div class="reduce-right" @click="ptzControl(8)"></div>
                  </a-col>
                </a-row>
                <a-row  style="margin-top: 12px">
                  <a-col :span="8">
                    <div class="reduce-left" @click="ptzControl(9)"></div>
                  </a-col>
                  <a-col :span="8">
                    <div class="reduce-middle-aperture"></div>
                  </a-col>
                  <a-col :span="8">
                    <div class="reduce-right" @click="ptzControl(10)"></div>
                  </a-col>
                </a-row>
              </div>
              <div style="margin-top: 67px;margin-left:28px;width: 99px;height: 25px;">
                <a-row>
                  <a-col :span="12">
                    <div class="step-text">步长：</div>
                  </a-col>
                  <a-col :span="12">
                    <a-input-number style="width: 54px;" v-model="stepNumber" :min="1" :max="20" />
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ptzControl } from "@/api/cameraPtz";
export default {
name: "MonitorCameraPtz",
  props: {
    positionLeft:{
      type:Number
    },
    positionTop:{
      type:Number
    },
    cameraData:{}
  },
  data(){
    return{
      stepNumber:5,
      lightParam:11,
      ytControlParams: {
        cameraNum: '',
        action: '',
        step: '',
        user: ''
      },
    }
  },
  methods:{
    cameraPtzClose(){
      this.$emit('cameraPtzClose',false)
    },
    ptzControl(action){
      this.ytControlParams.cameraNum = this.cameraData.cameraNum
      this.ytControlParams.cameraGateway = this.cameraData.gatewayNum
      this.ytControlParams.step = this.stepNumber
      this.ytControlParams.user = ''
      this.ytControlParams.action = action
      console.log(this.ytControlParams)

      ptzControl(this.ytControlParams).then((res)=>{
        console.log(res)
      }).catch((err)=>{
        console.log(err)
      })
    },
    lightClick(){
      // 11:灯光关 12:灯光开
      if(this.lightParam === 11){ // 开灯
        this.lightParam = 12
      }else { // 关灯
        this.lightParam = 11
      }
      // console.log(this.lightParam)
      this.ptzControl(this.lightParam)
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.monitorCameraPtz{

}
.monitorCameraPtz /deep/ .layerControl {
  width: 288px;
  position: absolute;
  z-index: 999;
}
.monitorCameraPtz /deep/ .layerTop {
  height: 16px;
  background-image: url("../../../assets/images/div288_16/bg_ctrl_inf_top.png");
}

.monitorCameraPtz /deep/ .layerMiddle {
  height: 318px;
  background-image: url("../../../assets/images/div288_16/bg_ctrl_inf_middle.png");
}

.monitorCameraPtz /deep/ .layerBottom {
  height: 16px;
  background-image: url("../../../assets/images/div288_16/bg_ctrl_inf_bottom.png");
}
.monitorCameraPtz /deep/ .layerContent {
  position: absolute;
  top: 16px;
}
.monitorCameraPtz /deep/ .ptz-name-text{
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 18px;
  color: #0088FF;
}
.monitorCameraPtz /deep/ .play-close{
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url("../../../assets/images/cameraPlayer/btn_closewindow_normal.png");
}
.monitorCameraPtz /deep/ .play-close:hover{
  background-image: url("../../../assets/images/cameraPlayer/btn_closewindow_hover.png");
}
.monitorCameraPtz /deep/ .ptz-table{
  width: 120px;
  height: 120px;
  margin-top: 50px;
}
.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr{
  height: 40px;
}
.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr-td_left_top{
  width: 40px;
  background-image: url('../../../assets/images/cameraPlayer/bg_ctrl_left_up.png')
}
.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr-td_middle_top{
  width: 40px;
  cursor: pointer;
  background-image: url('../../../assets/images/cameraPlayer/btn_ctrl_up.png')
}
.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr-td_middle_top:hover{
  background-image: url('../../../assets/images/cameraPlayer/btn_ctrl_up_hover.png')
}
.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr-td_right_top{
  width: 40px;
  background-image: url('../../../assets/images/cameraPlayer/bg_ctrl_right_up.png')
}

.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr-td_left_middle{
  width: 40px;
  cursor: pointer;
  background-image: url('../../../assets/images/cameraPlayer/btn_ctrl_left.png')
}
.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr-td_left_middle:hover{

  background-image: url('../../../assets/images/cameraPlayer/btn_ctrl_left_hover.png')
}
.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr-td_middle_middle{
  width: 40px;
  background-image: url('../../../assets/images/cameraPlayer/bg_ctrl_middle.png')
}
.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr-td_middle_right{
  width: 40px;
  cursor: pointer;
  background-image: url('../../../assets/images/cameraPlayer/btn_ctrl_right.png')
}
.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr-td_middle_right:hover{
  background-image: url('../../../assets/images/cameraPlayer/btn_ctrl_right_hover.png')
}

.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr-td_left_bottom{
  width: 40px;
  background-image: url('../../../assets/images/cameraPlayer/bg_ctrl_left_down.png')
}
.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr-td_middle_bottom{
  width: 40px;
  cursor: pointer;
  background-image: url('../../../assets/images/cameraPlayer/btn_ctrl_down.png')
}
.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr-td_middle_bottom:hover{
  background-image: url('../../../assets/images/cameraPlayer/btn_ctrl_down_hover.png')
}
.monitorCameraPtz /deep/ .ptz-table .ptz-table-tr-td_right_bottom{
  width: 40px;
  background-image: url('../../../assets/images/cameraPlayer/bg_ctrl_right_down.png')
}
.monitorCameraPtz /deep/ .light-text{

  font-size: 14px;
  font-family: Microsoft YaHei;
  color: #0088FF;
}
.monitorCameraPtz /deep/ .light-image-off{
  width: 54px;
  height: 24px;
  cursor: pointer;
  background-image: url("../../../assets/images/cameraPlayer/btn_lightoff_normal.png");
}
.monitorCameraPtz /deep/ .light-image-off:hover{
  background-image: url("../../../assets/images/cameraPlayer/btn_lightoff_hover.png");
}

.monitorCameraPtz /deep/ .light-image-on{
  width: 54px;
  height: 24px;
  cursor: pointer;
  background-image: url("../../../assets/images/cameraPlayer/btn_lighton_normal.png");
}
.monitorCameraPtz /deep/ .light-image-on:hover{
  background-image: url("../../../assets/images/cameraPlayer/btn_lighton_hover.png");
}

.monitorCameraPtz /deep/ .reduce-left{
  width: 40px;
  height: 32px;
  cursor: pointer;
  background-image: url("../../../assets/images/cameraPlayer/btn_reduce_normal.png");
}
.monitorCameraPtz /deep/ .reduce-left:hover{
  background-image: url("../../../assets/images/cameraPlayer/btn_reduce_hover.png");
}
.monitorCameraPtz /deep/ .reduce-middle-zoom{
  width: 40px;
  height: 32px;
  background-image: url("../../../assets/images/cameraPlayer/bg_zoom.png");
}
.monitorCameraPtz /deep/ .reduce-middle-focus{
  width: 40px;
  height: 32px;
  background-image: url("../../../assets/images/cameraPlayer/bg_focus.png");
}
.monitorCameraPtz /deep/ .reduce-middle-aperture{
  width: 40px;
  height: 32px;
  background-image: url("../../../assets/images/cameraPlayer/bg_aperture.png");
}
.monitorCameraPtz /deep/ .reduce-right{
  width: 40px;
  height: 32px;
  cursor: pointer;
  background-image: url("../../../assets/images/cameraPlayer/btn_add_normal.png");
}
.monitorCameraPtz /deep/ .reduce-right:hover{
  background-image: url("../../../assets/images/cameraPlayer/btn_add_hover.png");
}
.monitorCameraPtz /deep/ .step-text{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 25px;
  color: #0088FF;
}
.monitorCameraPtz /deep/ .ant-input-number{
  background-color: transparent;
  color:#0088FF;
  border: 1px solid #02418A;
}
.monitorCameraPtz /deep/ .ant-input-number-handler{
  background-color: #02418A;
  color: #0088FF;
}
</style>
