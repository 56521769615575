<!--视频播放窗口主视频窗口实现-->
<template>
  <div class="monitorCameraPlay">
    <div class="layerControl" :style="{left:positionLeft+'px',top:positionTop+'px'}">
      <div class="layerTop"></div>
      <div class="layerMiddle"></div>
      <div class="layerBottom"></div>
      <div class="layerContent">
        <div style="width: 512px;padding:0px 16px 0px 16px; ">
          <a-row style="height: 18px;line-height: 18px;">
            <a-col :span="23" class="camera-name-text" :title="cameraData.cameraName">
              {{getCameraName()}}
            </a-col>
            <a-col :span="1">
              <div class="play-close" @click="cameraPlayClose"></div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 8px;">
            <a-col :span="24">
              <div style="height: 270px;" id="cameraVideoPlay"></div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 8px;" class="camera-tools">

            <div style="float: left;">
              <a href="#" @click.prevent="cameraInfoClose">摄像机详情</a>
            </div>

            <div style="float: right;">
              <a href="#" v-if="userInfo().isHighStream" style="margin-right: 10px;" @click.prevent="highStream" :class="{checked: currentStreamType === STREAM_TYPE.高码流}">高码流</a>
              <a href="#" style="margin-right: 10px;" @click.prevent="lowStream" :class="{checked: currentStreamType === STREAM_TYPE.低码流}">低码流</a>
              <a href="#" style="margin-right: 10px;" @click.prevent="fourScreen">四分屏</a>
              <a href="#" style="margin-right: 10px;" @click.prevent="nineScreen" v-if="buttonVisible.nineScreen">九分屏</a>
              <a href="#" style="margin-right: 10px;" @click.prevent="cameraPtzClose">云台控制</a>
              <a href="#" style="margin-right: 10px;" @click.prevent="screenshotClick">截图</a>
              <img class="full-sreen" style="margin-right: 10px;" title="全屏" @click="aliyunFullScreen">
            </div>


<!--            <a-col :span="12">-->
<!--              <a href="#" @click.prevent="cameraInfoClose">摄像机详情</a>-->
<!--            </a-col>-->
<!--            <a-col :span="12">-->
<!--              <a-row >-->
<!--                <a-col :span="17" style="text-align: right">-->
<!--                  <a href="#" style="margin-right: 10px;" @click.prevent="fourScreen">四分屏</a>-->
<!--                  <a href="#" style="margin-right: 10px;" @click.prevent="cameraPtzClose">云台控制</a>-->
<!--                </a-col>-->
<!--                <a-col :span="4">-->
<!--                  <a href="#" style="margin-right: 10px;" @click.prevent="screenshotClick">截图</a>-->
<!--                </a-col>-->
<!--                <a-col :span="3">-->
<!--                  <div class="full-sreen" title="全屏" @click="aliyunFullScreen"></div>-->
<!--                </a-col>-->
<!--              </a-row>-->
<!--            </a-col>-->
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCameraVideoUrl } from "@/api/cameraVideo";
import { cameraStreamOperate } from "../../../api/stream";
import { STREAM_TYPE } from "../../../core/enums";
import { mapGetters } from 'vuex'
export default {
name: "MonitorCameraPlay",
  props: {
    isShow: {
      type: Boolean
    },
    positionLeft:{
      type:Number
    },
    positionTop:{
      type:Number
    },
    isShowCameraInfo: {
      type: Boolean
    },
    isShowPtZ: {
      type: Boolean
    },
    cameraData:{},
    buttonVisible: { // 控制按钮是否可见,目前仅对9分屏进行显隐控制
      type: Object,
      default: () => {
        return {
          nineScreen: true
        }
      }
    }
  },
  data(){
    return{
      STREAM_TYPE,
      currentStreamType: STREAM_TYPE.低码流, // 当前选中的码流类型
      highStreamOpened: false, // 是否打开过高码流
      player:{},
      videoData: null // 记录当前播放的视频相关数据, 用于分屏时的第一个屏
    }
  },
  methods:{
    ...mapGetters(['userInfo']),
    aliyunPlayer (playUrl) {
      // eslint-disable-next-line no-undef
      this.player = new Aliplayer({
            "id": 'cameraVideoPlay',
            "source": playUrl,
            // "width": "320px",
            // "height": "180px",
            // "videoWidth":"320px",
            // "videoHeight":"180px",
            "autoplay": true,
            "isLive": true,
            "rePlay": false,
            "showBuffer": false,
            "snapshot": true,
            "playsinline": true,
            "language": "zh-cn",
            "enableStashBufferForFlv": true,
            "preload": true,
            "useH5Prism": true,
            "liveRetry": 5,
            "waitingTimeout": 3,
            "controlBarVisibility": "hover",
            "skinLayout": [
              {"name": "bigPlayButton","align": "blabs","x": 30,"y": 80},
              {"name": "liveDisplay","align": "blabs","x": 15, "y": -8},
              {"name": "errorDisplay","align": "tlabs","x": 0,"y": 0},
              {"name": "infoDisplay","align": "cc"},
              {
                name: "H5Loading", align: "cc"
              }
            ]
          },function(player) {
            player.play();
          }
      )
      this.player.on('error',function(){
        let errDom = document.getElementsByClassName('prism-ErrorMessage')
        errDom[0].innerHTML = '<div style="color: #fff;font-size: 18px;text-align: center;margin-top: 80px;">播放失败</div>'
      });
    },

    aliyunFullScreen(){
      //全屏实现
      this.player.fullscreenService.requestFullScreen()
    },
    getCameraPlayerUrl(camera, videoType = STREAM_TYPE.低码流){
      this.currentStreamType = videoType
      this.dispose()
      this.camera = camera
      let cameraNum = camera.cameraNum
      // let cameraNum = '3243124324312413'
      let param=  {
        videoType: videoType,
        cameraNum: cameraNum
      }
      getCameraVideoUrl(param).then(res=>{
        let playUrl = res.data.videoRequestUrl[0].hls_url
        this.videoData = camera
        this.aliyunPlayer(playUrl);
      }).catch(err=>{
        console.log(err)
        let errUrl = 'https://spell.gstscc.cn/live/stream01.m3u8?auth_key=1607167862-0-0-f9cb9eed814bf510288e2c4e454283ed'
        this.aliyunPlayer(errUrl)
      })
    },
    cameraInfoClose(){
      this.$emit('cameraInfoClose',!this.isShowCameraInfo)
    },
    cameraPtzClose(){
      this.$emit('cameraPtzClose',!this.isShowPtZ)
    },
    cameraPlayClose(){
      // this.player.dispose()
      this.closeHighStream(this.camera)
      this.dispose()
      this.$emit('cameraPlayClose', 0)
    },
    closeHighStream (camera) { // 关闭高码流
      if (this.highStreamOpened) {
        let param = {
          cameraNum: camera.cameraNum,
          videoType: STREAM_TYPE.高码流,
          streamStatus: 0,
          gatewayNum: camera.gatewayNum,
        }
        cameraStreamOperate(param).then(() => {})
      }
    },
    highStream () { // 高码流
      this.getCameraPlayerUrl(this.camera, STREAM_TYPE.高码流)
      this.highStreamOpened = true // 是否打开过高码流
    },
    lowStream () { // 低码流
      this.getCameraPlayerUrl(this.camera, STREAM_TYPE.低码流)
      this.closeHighStream(this.camera)
    },
    fourScreen () { // 四分屏
      this.closeHighStream(this.camera)
      this.dispose()
      this.$store.fourScreen = true // 是否处于四分屏状态
      this.$eventBus.$emit('fourScreen', this.videoData)
      this.$emit('cameraPlayClose', 1)
    },
    nineScreen () { // 九分屏
        this.closeHighStream(this.camera)
        this.dispose()
        this.$store.nineScreen = true // 是否处于九分屏状态
        this.$eventBus.$emit('nineScreen', this.videoData)
        this.$emit('cameraPlayClose', 2)
    },
    dispose () { // 断开流
      if (this.player.dispose !== undefined) {
        this.player.dispose() // 先关闭之前的再播放新的
      }
    },
    screenshotClick(){
      //截图实现
      var canvas = document.createElement('canvas');
      var video = this.player.tag;
      canvas.width = video.videoWidth,//视频原有尺寸
      canvas.height = video.videoHeight;//视频原有尺寸
      console.log(video.videoWidth,video.videoHeight)
      var ctx = canvas.getContext('2d');
      ctx.save();
      //判断用户是否对视频做过镜像
      var image = this.player.getImage();
      if (image == "vertical")//垂直镜像
      {
        ctx.translate(0, canvas.height);
        ctx.scale(1, -1);
      } else if (image == "horizon")//水平镜像
      {
        ctx.translate(canvas.width, 0);
        ctx.scale(-1, 1);
      }
      //视频的当前画面渲染到画布上
      ctx.drawImage(video, 0, 0);
      ctx.restore();
      var pictureData = canvas.toDataURL("image/jpeg");
      var downloadElement = document.createElement('a')
      downloadElement.setAttribute('href', pictureData)
      var fileName = 'Aliplayer' + Date.now() + '.png'
      downloadElement.setAttribute('download', fileName)
      downloadElement.click()
      pictureData = null
    },
    getCameraName(){
      return this.cameraData.cameraName
    }
  },
  beforeDestroy () {
    this.closeHighStream(this.camera)
  },
  mounted() {
    this.$nextTick(() => {
      // this.getCameraPlayerUrl(this.cameraData)
    })

  },
  created() {

  }
}
</script>

<style lang="less" scoped>
.monitorCameraPlay{

}
.monitorCameraPlay /deep/ .layerControl {
  width: 512px;
  position: absolute;
  z-index: 999;
}
.monitorCameraPlay /deep/ .layerTop {
  height: 16px;
  background-image: url("../../../assets/images/div512_16/bg_camera_top.png");
}

.monitorCameraPlay /deep/ .layerMiddle {
  height: 318px;
  background-image: url("../../../assets/images/div512_16/bg_camera_middle.png");
}

.monitorCameraPlay /deep/ .layerBottom {
  height: 16px;
  background-image: url("../../../assets/images/div512_16/bg_camera_bottom.png");
}
.monitorCameraPlay /deep/ .layerContent {
  position: absolute;
  top: 16px;

  video {
    object-fit: fill !important;
  }
}
.monitorCameraPlay /deep/ .camera-name-text{
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 18px;
  color: #0088FF;
}
.monitorCameraPlay /deep/ .ant-btn-link{
  padding-right: 8px;
}
.monitorCameraPlay /deep/ .screen-shot{
  width: 16px;
  height: 16px;
  margin-right: 8px;
  cursor: pointer;
  background-image: url("../../../assets/images/cameraPlayer/btn_media_screenshot.png");
}
.monitorCameraPlay /deep/ .screen-shot:hover{
  background-image: url("../../../assets/images/cameraPlayer/btn_media_screenshot_hover.png");
}
.monitorCameraPlay /deep/ .full-sreen{
  width: 16px;
  height: 16px;
  cursor: pointer;
  content: url("../../../assets/images/cameraPlayer/btn_media_fullscreen.png");
  /*background-image: url("../../../assets/images/cameraPlayer/btn_media_fullscreen.png");*/
}
.monitorCameraPlay /deep/ .full-sreen:hover{
  /*background-image: url("../../../assets/images/cameraPlayer/btn_media_fullscreen_hover.png");*/
  content: url("../../../assets/images/cameraPlayer/btn_media_fullscreen_hover.png");
}
.monitorCameraPlay /deep/ .play-close{
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url("../../../assets/images/cameraPlayer/btn_closewindow_normal.png");
}
.monitorCameraPlay /deep/ .play-close:hover{
  background-image: url("../../../assets/images/cameraPlayer/btn_closewindow_hover.png");
}
.monitorCameraPlay /deep/ .camera-tools{
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 18px;
  color: #0088FF;

  .checked {
    filter: brightness(2);
  }
}
</style>
