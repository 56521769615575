<!--视频播放窗体摄像机详情查看-->
<template>
  <div class="monitorCameraInfo">
    <div class="layerControl" :style="{left:positionLeft+'px',top:positionTop+'px'}">
      <div class="layerTop"></div>
      <div class="layerMiddle"></div>
      <div class="layerBottom"></div>
      <div class="layerContent">
        <div style="width: 288px;height: 228px;padding:0px 16px 0px 16px;">
          <a-row>
            <a-col :span="23" class="info-name-text" >
              摄像机详情
            </a-col>
            <a-col :span="1">
              <div class="play-close" @click="cameraInfoClose"></div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 24px;">
            <a-col :span="8" class="title-text">摄像机名称：</a-col>
            <a-col :span="16" style="height: 60px;" class="title-name-velue">
              {{cameraData.cameraName}}
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="10" class="title-text">所在行政区域：</a-col>
            <a-col :span="14" style="height: 18px;" class="title-velue">
              {{cameraData.area}}
            </a-col>
          </a-row>
          <a-row  style="margin-top: 10px;">
            <a-col :span="10" class="title-text">摄像机桩号：</a-col>
            <a-col :span="14" style="height: 18px;" class="title-velue">
              {{cameraData.pileNum}}
            </a-col>
          </a-row>
          <a-row  style="margin-top: 10px;">
            <a-col :span="10" class="title-text">所在位置：</a-col>
            <a-col :span="14" style="height: 18px;" class="title-velue">
              {{cameraData.poiName}}
            </a-col>
          </a-row>
          <a-row  style="margin-top: 10px;">
            <a-col :span="10" class="title-text">摄像机方向：</a-col>
            <a-col :span="14" style="height: 18px;" class="title-velue" v-if="cameraData.cameraOrientation == 0">
              上行
            </a-col>
            <a-col :span="14" style="height: 18px;" class="title-velue"  v-else-if="cameraData.cameraOrientation == 1">
              下行
            </a-col>
            <a-col :span="14" style="height: 18px;" class="title-velue"  v-else>
              双向
            </a-col>
          </a-row>
          <a-row  style="margin-top: 10px;">
            <a-col :span="10" class="title-text">管辖单位：</a-col>
            <a-col :span="14" style="height: 18px;" class="title-velue">
              {{cameraData.department}}
            </a-col>
          </a-row>
          <a-row  style="margin-top: 10px;">
            <a-col :span="16" class="title-text"></a-col>
            <a-col :span="8" style="height: 28px;">
              <div class="snapshot-view">查看快照</div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "MonitorCameraInfo",
  props: {
    positionLeft:{
      type:Number
    },
    positionTop:{
      type:Number
    },
    cameraData:{}
  },
  methods:{
    cameraInfoClose(){
      this.$emit('cameraInfoClose',false)
    }
  },
  mounted() {
    console.log(this.cameraData)
  }
}
</script>

<style scoped>
.monitorCameraInfo{

}
.monitorCameraInfo /deep/ .layerControl {
  width: 288px;
  position: absolute;
  z-index: 999;
}
.monitorCameraInfo /deep/ .layerTop {
  height: 16px;
  background-image: url("../../../assets/images/div288_16/bg_ctrl_inf_top.png");
}

.monitorCameraInfo /deep/ .layerMiddle {
  height: 318px;
  background-image: url("../../../assets/images/div288_16/bg_ctrl_inf_middle.png");
}

.monitorCameraInfo /deep/ .layerBottom {
  height: 16px;
  background-image: url("../../../assets/images/div288_16/bg_ctrl_inf_bottom.png");
}
.monitorCameraInfo /deep/ .layerContent {
  position: absolute;
  top: 16px;
}
.monitorCameraInfo /deep/ .info-name-text{
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 18px;
  color: #0088FF;
}
.monitorCameraInfo /deep/ .title-text{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 25px;
  color: #0088FF;
}
.monitorCameraInfo /deep/ .play-close{
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url("../../../assets/images/cameraPlayer/btn_closewindow_normal.png");
}
.monitorCameraInfo /deep/ .title-name-velue{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.85);
}
.monitorCameraInfo /deep/ .title-velue{
  text-align: right;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.85);
}
.monitorCameraInfo /deep/ .snapshot-view{
  text-align: center;
  width: 80px;
  height: 28px;
  border: 1px solid #0088FF;
  opacity: 1;
  border-radius: 17px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 28px;
  color: #0088FF;
  cursor: pointer;
}
</style>
