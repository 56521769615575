import { get } from '@/utils/request'
const URL = '/data/camera'


/**
 * 对相机流进行操作
 * @param cameraNum    摄像机编号
 * @param videoType    视频流类型(1高码流,0低码流)
 * @param streamStatus 操作类型(1推流,0禁推)
 * @param gatewayNum   网关编号
 */
export const cameraStreamOperate = p => get(URL + '/cameraStreamOperate' , p)

