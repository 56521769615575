<!--四分屏面板-->
<template>
  <div>
    <portal to="screen-modal">
      <div id="player-container" class="player-container" :style="{width: playContainerWidth + 'px'}" v-if="visible" v-show="show">

        <!--四个边角-->
        <div class="panel-corner">
          <img :src="Corner.BorderLeftTop">
          <img :src="Corner.BorderRightTop">
          <img :src="Corner.BorderLeftBottom">
          <img :src="Corner.BorderRightBottom">
        </div>

        <div id="panel-header" class="panel-header" :class="{'cursor-move': !isFullScreen}">
          <div class="panel-title">四分屏</div>
          <div class="play-zoom" :class="{'play-zoom-big': zoomStatus === 1, 'play-zoomm-small': zoomStatus === 0}" @click="zoom"></div>
          <div class="play-screen"  @click="fullScreen"></div>
          <div class="play-close" @click="close"></div>
        </div>

        <a-row type="flex" justify="center">

          <!--        <a-col :span="12" v-for="(item, index) of cameras" :key="index">-->
          <!--          <div :id="'camera-player-' + (index + 1)" class="single-video">-->
          <!--            <div class="single-video-header">G30甘肃定西定西段K1588+800下行</div>-->
          <!--          </div>-->
          <!--        </a-col>-->

          <!-- v-for直接循环数字是从1开始的,为了操作索引方便,在这里减1 -->
          <a-col :span="12" v-for="index in 4" :key="index">
            <div class="single-video" :style="{height: singleVideoHeight + 'px'}" ref="singleVideo" @click="videoClick($event, (index - 1))">
              <div :id="'camera-player-' + (index - 1)" class="camera-player"></div>
              <div class="single-video-header">
                <div class="single-video-title" :style="{width: zoomStatus === 0 ? '80%' : '120px'}"></div>
                <img @click="oneScreenClick($event, (index - 1))" class="btn-one-screen" src="../../../assets/images/cameraPlayer/btn_one_screen_normal.png">
                <img @click="endPlayClick($event, (index - 1))" class="btn-close-video" src="../../../assets/images/cameraPlayer/btn_close_video_window_normal.png">
              </div>
            </div>
          </a-col>
        </a-row>

      </div>
    </portal>
  </div>
</template>

<script>

  import Corner from '../../../assets/images/corner'
  import { getCameraVideoUrl } from "../../../api/cameraVideo"
  import notification from 'ant-design-vue/es/notification'

export default {
  name: "FourScreen",
  data(){
    return{
      Corner, // 四个边角
      player1: {},
      player2: {},
      player3: {},
      player4: {},
      visible: false, // 是否存在
      show: false, // 是否显示
      playContainerWidth: 880,
      singleVideoHeight: 300, // 单个视频的高度
      zoomStatus: 0, // 面板放大还是缩小的标记 0:放大, 1:缩小
      isFullScreen: false, // 是否全屏
      cameras: [], // 四分屏数据
      videoIndex: -1, // 四分屏全局指针,记录添加到第几个了(从1开始)
      playerStatus:[ // 记录四个屏幕的状态,用于控制接下来的视频被哪个窗口播放
          {
              index: 0, // 1号窗口
              hasVideo: false, // 是否有视频
              selected: false, // 是否被选中
              cameraNum: null, // 相机编号(防止重复播放相同视频)
              cameraData: null // 相机数据
          },
          {
              index: 1,
              hasVideo: false,
              selected: false,
              cameraNum: null,
              cameraData: null
          },
          {
              index: 2,
              hasVideo: false,
              selected: false ,
              cameraNum: null,
              cameraData: null
          },
          {
              index: 3,
              hasVideo: false,
              selected: false ,
              cameraNum: null,
              cameraData: null
          }
      ]
    }
  },
  methods:{
    aliyunPlayer (playUrl, id, index) {
      // eslint-disable-next-line no-undef
        let player = new Aliplayer({
                "id": id,
                "source": playUrl,
                // "width": "320px",
                // "height": "180px",
                // "videoWidth":"320px",
                // "videoHeight":"180px",
                "autoplay": true,
                "isLive": true,
                "rePlay": false,
                "showBuffer": false,
                "snapshot": true,
                "playsinline": true,
                "language": "zh-cn",
                "enableStashBufferForFlv": true,
                "preload": true,
                "useH5Prism": true,
                "liveRetry": 5,
                "waitingTimeout": 3,
                "controlBarVisibility": "hover",
                "skinLayout": [
                  {"name": "bigPlayButton","align": "blabs","x": 30,"y": 80},
                  {"name": "liveDisplay","align": "blabs","x": 15, "y": -8},
                  {"name": "errorDisplay","align": "tlabs","x": 0,"y": 0},
                  {"name": "infoDisplay","align": "cc"},
                  {
                    name: "H5Loading", align: "cc"
                  }
                ]
              },function(player) {
                player.play();
              }
        )
        player.on('error',function(){
            let errDom = document.getElementsByClassName('prism-ErrorMessage')
            errDom[0].innerHTML = '<div style="color: #fff;font-size: 18px;text-align: center;margin-top: 80px;">播放失败</div>'
        });
        switch (index) {
            case 0:
                this.player1 = player
                break
            case 1:
                this.player2 = player
                break
            case 2:
                this.player3 = player
                break
            case 3:
                this.player4 = player
                break
        }
    },
    cameraPlayClose(){
      // this.player.dispose()
      if (this.player.dispose !== undefined) {
        this.player.dispose() // 先关闭之前的再播放新的
      }
    },
      oneScreenClick (event, index) { // 切换回单屏
          event.stopPropagation()
          this.$store.fourScreen = false
          this.$emit('oneScreen', this.playerStatus[index].cameraData)
          this.show = false
      },
      endPlayClick (event, index) { // 结束播放单个视频
          event.stopPropagation()
          switch (index) {
              case 0:
                  this.destroyVideo(this.player1)
                  break
              case 1:
                  this.destroyVideo(this.player2)
                  break
              case 2:
                  this.destroyVideo(this.player3)
                  break
              case 3:
                  this.destroyVideo(this.player4)
                  break
          }
          document.getElementById('camera-player-' + index).innerHTML = '' // 清空Video组件
          document.getElementById('camera-player-' + index).classList.remove('prism-player') // 移除Video组件创建的样式
          document.getElementById('camera-player-' + index).nextSibling.style.display = 'none' // 隐藏header
          this.playerStatus[index].hasVideo = false
          this.playerStatus[index].selected = false
          this.playerStatus[index].cameraNum = null
      },
    videoClick (event, index) {
        // this.videoIndex = 0
        let dom = this.$refs.singleVideo[index]
        this.playerStatus.forEach(p => {
            p.selected = false
        })
        if (dom.classList.contains('single-video-selected')) { // 重复点击已选中的,取消选中状态
            dom.classList.remove('single-video-selected')
        } else {  // 点击的是没选中的,清空全部状态,为点击的添加选中状态
            this.$refs.singleVideo.forEach(v => {
                v.classList.remove('single-video-selected')
                v.style.zIndex = 1
            })
            this.playerStatus[index].selected = true
            dom.classList.add('single-video-selected')
            dom.style.zIndex = 10 // 防止选中阴影效果被遮盖
        }
    },
    open (videoData) { // 从无到有,打开窗口
        this.videoIndex = -1
        this.visible = true
        this.show = true
        this.$nextTick(() => {
          this.bindDrag()
        })
        this.addVideo(videoData)
    },
      showWindow () { // 从隐藏到显示(1分屏切回4分屏)
          this.show = true
      },
    fullScreen () { // 切换全屏
      // 屏幕高度
      let screenHeight = document.documentElement.clientHeight
      this.singleVideoHeight = (screenHeight - 38) / 2
      this.playContainerWidth = document.documentElement.clientWidth + 20

      // 面板移动
      let container = document.getElementById('player-container');
      container.style.left = '-10px'
      container.style.top = '-10px'

      // 设置为全屏状态
      this.isFullScreen = true
      // 设置为放大状态
      this.zoomStatus = 0
    },
    zoom () { // 调整面板大小,方便操作地图
        if (this.zoomStatus === 0) {
            this.singleVideoHeight = 120
            this.playContainerWidth = 440
            this.zoomStatus = 1
        } else {
            this.singleVideoHeight = 300
            this.playContainerWidth = 880
            this.zoomStatus = 0
        }
        this.isFullScreen = false
    },
    close () {
        this.visible = false
        this.$store.fourScreen = false // 是否处于四分屏状态
        this.$emit('onClose')
        this.destroyVideo(this.player1)
        this.destroyVideo(this.player2)
        this.destroyVideo(this.player3)
        this.destroyVideo(this.player4)
        this.playerStatus.forEach(p => {
            p.selected = false
            p.hasVideo = false
            p.cameraNum = null
            p.cameraData = null
        })
        // 还原面板
        this.singleVideoHeight = 300
        this.playContainerWidth = 880
        this.zoomStatus = 0
        this.isFullScreen = false
    },
  destroyVideo (player) { // 销毁视频
      if (player.dispose !== undefined && player.dispose !== null) {
          player.dispose() // 先关闭之前的再播放新的
      }
  },
    addVideo (camera) { // 向四分屏中添加视频
      let param=  {
        videoType: 0,
        cameraNum: camera.cameraNum
      }

      // 防止一个视频点击多次
      if (this.playerStatus.find(p => p.cameraNum === camera.cameraNum) !== undefined) {
          notification.error({
              message: '重复视频',
              description: '该视频已播放,请勿重复播放'
          })
          return
      }

        let playIndex = this.getPlayIndex()
        this.playerStatus[playIndex].cameraNum = camera.cameraNum
        this.playerStatus[playIndex].cameraData = camera
        switch (playIndex) {
            case 0:
                this.destroyVideo(this.player1)
                break
            case 1:
                this.destroyVideo(this.player2)
                break
            case 2:
                this.destroyVideo(this.player3)
                break
            case 3:
                this.destroyVideo(this.player4)
                break
        }

      getCameraVideoUrl(param).then(res=>{
        let playUrl = res.data.videoRequestUrl[0].hls_url
        let id = 'camera-player-' + playIndex
        this.aliyunPlayer(playUrl, id, playIndex);
        document.getElementById(id).nextSibling.style.display = 'block'
        document.getElementById(id).nextSibling.getElementsByClassName("single-video-title")[0].innerHTML = camera.cameraName
      }).catch(err=>{
        console.log(err)
        // let errUrl = 'https://spell.gstscc.cn/live/stream01.m3u8?auth_key=1607167862-0-0-f9cb9eed814bf510288e2c4e454283ed'
        // this.aliyunPlayer(errUrl)
      })
        this.$nextTick(() => {
            // 清空选中状态
            this.playerStatus.forEach(p => {
                p.selected = false
            })
            this.$refs.singleVideo.forEach(v => {
                v.classList.remove('single-video-selected')
            })
        })
    },
      getPlayIndex () { // 获取将要播放视频的窗口编号(如果被选中,就在被选中的上面播放,如果没有被选中的,就按顺序在黑的上面播放)
          // 有选中的播放器
          let playerSelected = this.playerStatus.find(p => p.selected)
          if (playerSelected !== undefined) {
              this.playerStatus[playerSelected.index].hasVideo = true
              return playerSelected.index
          }
          // 没有选中播放器,按顺序选空闲的窗口
          for (let i = 0; i < this.playerStatus.length; i++) {
              if (!this.playerStatus[i].hasVideo) {
                  this.playerStatus[i].hasVideo = true
                  return this.playerStatus[i].index
              }
          }
          // 没有选中, 也没有空闲窗口
          // 将默认索引归位
          // let playerHasVideo = this.playerStatus.filter(p => p.hasVideo)
          // if (playerHasVideo !== undefined && playerHasVideo.length === this.playerStatus.length) {
          //     this.videoIndex = -1
          // }
          // 根据默认索引播放
          this.videoIndex = this.videoIndex === 3 ? 0 : this.videoIndex + 1
          return this.videoIndex
      },
      bindDrag () { // 绑定拖拽事件
        this.$nextTick(() => {
            let _this = this
            //获取元素
            let dv = document.getElementById('panel-header');
            let container = document.getElementById('player-container');
            // 页面宽度
            let bw = document.body.clientWidth
            // 页面高度
            let bh = document.body.clientHeight
            let cw // 面板宽度
            let ch // 面板高度

            let x = 0;
            let y = 0;
            let l = 0;
            let t = 0;
            let isDown = false;
            //鼠标按下事件
            dv.onmousedown = function(e) {
                cw = container.offsetWidth
                ch = container.offsetHeight

                //获取x坐标和y坐标
                x = e.clientX;
                y = e.clientY;

                //获取左部和顶部的偏移量
                l = container.offsetLeft;
                t = container.offsetTop;
                //开关打开
                isDown = true;

                //鼠标移动
                window.onmousemove = function(e) {
                    if (isDown == false) {
                        return;
                    }
                    //获取x和y
                    let nx = e.clientX;
                    let ny = e.clientY;
                    //计算移动后的左偏移量和顶部的偏移量
                    let nl = nx - (x - l);
                    let nt = ny - (y - t);

                    // 防止拖拽出边界
                    // 70-顶部工具栏高度, 5-四周留白
                    nl = nl <= 5 ? 5 : nl
                    nl = nl >= (bw - cw - 5) ? (bw - cw - 5) : nl
                    nt = nt <= 5 ? 5 : nt
                    nt = nt >= (bh - ch - 5) ? (bh - ch - 5) : nt

                    if (!_this.isFullScreen) {
                      container.style.left = nl + 'px';
                      container.style.top = nt + 'px';
                    }
                }
            }
            //鼠标抬起事件
            window.onmouseup = function() {
                //开关关闭
                isDown = false;
            }
        })
      }
  },
  mounted() {

  }
}
</script>

<style lang="less" scoped>

  .player-container /deep/ video {
    object-fit: fill !important;
      pointer-events: none;
  }

  .panel-corner {
  & img:nth-child(1) {
      position: absolute;
      top: -2px;
      left: -2px;
    }

  & img:nth-child(2) {
      position: absolute;
      right: -2px;
      top: -2px;
    }

  & img:nth-child(3) {
      position: absolute;
      bottom: -2px;
      left: -2px;
    }

  & img:nth-child(4) {
      position: absolute;
      right: -2px;
      bottom: -2px;
    }
  }

  .player-container {
    position: absolute;
    top: 90px;
    left: 520px;
    /*right: 0px;*/
    /*margin: auto;*/
    /*width: 880px;*/
    /*height: 660px;*/
    z-index: 99;
    background-color: rgba(21, 23, 97, 0.85);
    padding: 0px 16px 16px 16px;
      transition: width 0.5s;

    .cursor-move {
      cursor: move;
    }

    .panel-header {
      height: 42px;

        .panel-title {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 42px;
            color: #0088FF;
        }

      .play-zoom {
          position: absolute;
          right: 46px;
          top: 12px;
          width: 16px;
          height: 16px;
          cursor: pointer;
      }
        .play-zoom-big {
            background-image: url("../../../assets/images/cameraPlayer/btn_big_normal.png");
            &:hover {
                background-image: url("../../../assets/images/cameraPlayer/btn_big_hover.png")
            }
        }
        .play-zoom-small {
            background-image: url("../../../assets/images/cameraPlayer/btn_small_normal.png");
            &:hover {
                background-image: url("../../../assets/images/cameraPlayer/btn_small_hover.png")
            }
        }

      .play-screen {
        position: absolute;
        right: 72px;
        top: 12px;
        width: 16px;
        height: 16px;
        cursor: pointer;
        background-image: url("../../../assets/images/cameraPlayer/btn_fullscreen_normal.png");

        &:hover {
          background-image: url("../../../assets/images/cameraPlayer/btn_fullscreen_hover.png")
        }
      }

      .play-close{
        position: absolute;
        right: 20px;
        top: 12px;
        width: 16px;
        height: 16px;
        cursor: pointer;
        background-image: url("../../../assets/images/cameraPlayer/btn_closewindow_normal.png");

        &:hover {
          background-image: url("../../../assets/images/cameraPlayer/btn_closewindow_hover.png")
        }
      }
    }

    .single-video {
      /*height: 300px;*/
      position: relative;
      background-color: black;
      border: 1px solid rgba(255, 255, 255, .15);
      display: flex;
      overflow: hidden;
      transition: height .5s;

      &:after {
        content: 'No Video';
        margin: auto;
        left: 0px;
        right: 0px;
        color: rgba(255, 255, 255, .6);
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
      }

        .camera-player {
            position: absolute;
            pointer-events: none;
            height: 100% !important;
        }

      .single-video-header {
        z-index: 999;
        /*background-color: red;*/
        /*background: linear-gradient(to bottom, #706c6d 0%, #dddddd 100%);*/
        background: rgba(0, 0, 0, .65);
        opacity: .9;
        position: absolute;
        width: 100%;
        height: 24px;
        line-height: 24px;
        top: -32px;
        transition: top .3s;
        padding-left: 8px;
        color: rgba(255, 255, 255, .85);
        font-family: PingFang SC;
        font-weight: 400;
        display: none;
        white-space: nowrap;

          .single-video-title {
              width: 80%;
              text-overflow: ellipsis;
              overflow: hidden;
          }
          img {
              position: absolute;
              top: 3px;
              cursor: pointer;
          }
          .btn-one-screen {
              right: 30px;
              &:hover {
                  content: url("../../../assets/images/cameraPlayer/btn_one_screen_hover.png");
              }
          }
          .btn-close-video {
              right: 10px;
              &:hover {
                  content: url("../../../assets/images/cameraPlayer/btn_close_video_window_hover.png");
              }
          }
      }

      &:hover .single-video-header {
        top: 0px;
      }
    }

      .single-video-selected {
          box-shadow:0px 0px 5px 2px #0088ff;
      }

  }


</style>
